<template>
  <div class="zl-list">
    <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>通用设置</a-breadcrumb-item>
        <a-breadcrumb-item>更新日志</a-breadcrumb-item>
        <a-breadcrumb-item>{{upgrade_id?'编辑更新日志':'新增更新日志'}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box smartservice">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <div class="title flts-title">
          <h3>{{upgrade_id?'编辑日志':'新增更新日志'}}</h3>
          <div>
            <a-button :loading="confirmLoading" size="small" type="primary" @click="handleOk">保存</a-button>
            <a-button size="small" style="margin-left:10px" @click="handleCancel">返回</a-button>
          </div>
      </div>
      <a-form :form="form" layout="vertical">
        <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
          <a-input v-if="item.type === 'input'" placeholder="请输入" v-decorator="[item.name, { rules: item.rules }]"/>
          <a-date-picker style='width:100%' v-if="item.type === 'time'" format="YYYY-MM-DD" v-decorator="[item.name, { rules: item.rules }]" />
          <l-editor v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'textarea2'" name="content" :data="editor.content"/>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
const formData = [
  {
    name: 'upgrade_version',
    label: '更新版本',
    type: 'input',
    rules: [{ required: true, message: '请输入更新版本!' }],
  },
  {
    name: 'upgrade_date',
    label: '更新日期',
    type: 'time',
    rules: [{ required: true, message: '请选择更新日期!' }],
  },
  {
    name: 'upgrade_content',
    label: '更新内容',
    type: 'textarea2',
    rules: [{ required: true, message: '请输入更新内容!' }]
  },
]

import moment from 'moment'
export default {
  name: 'EditModal',
  provide() {
      return {
          parent: this
      }
  },
  data() {
    return {
      visible: false,
      loading: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      content: '',
      editor: {},
      upgrade_id:'',
    }
  },

  async created() {
    this.visible = true
    this.upgrade_id = this.$route.query.id
    if(this.upgrade_id){
      this.loading = true
    }
    await this.$nextTick()
    if(this.upgrade_id){
      let detailInfo = await this.getDetail()
      this.editor.content = detailInfo.upgrade_content
      this.form.setFieldsValue({
          upgrade_version:detailInfo.upgrade_version,
          upgrade_date:moment(detailInfo.upgrade_date),
          upgrade_content:detailInfo.upgrade_content,
      })
      this.loading = false
    }
  },
  methods: {
    async getDetail(){
      let res = await this.$store.dispatch('enterpriseUpgradeLogDetailAction',{data:{id:this.upgrade_id}})
      return res.data
    },
    async handleOk() {
      let params = await this.form.validateFields()
      params.upgrade_content = this.editor.content
      params.upgrade_date = moment(params.upgrade_date).format('YYYY-MM-DD')
      if(this.upgrade_id){
        params.id = this.upgrade_id
      }
      this.confirmLoading = true
      await this.$store.dispatch(this.upgrade_id?'enterpriseUpgradeLogUpdateAction':'enterpriseUpgradeLogAddAction', {data:params})
      .then(res=>{
        this.confirmLoading = false
        this.$message.success('操作成功~')
        this.$router.go(-1)
      })
      .catch(err=>{
        this.confirmLoading = false
      })

      
    },
    handleCancel() {
      this.$router.go(-1)
    },
    changeEditor(key, value) {
      this.editor[key] = value
      this.form.setFieldsValue({upgrade_content:value})
    },
  }
}
</script>
<style lang="scss" scoped>
  .smartservice{
    width: 70%;
    margin: 0 auto;
  }
  // .ql-container {
  //   margin: 50px auto;
  //   max-width: 720px;
  // }
  // #smartservice_content {
  //   height: 350px;
  // }
</style>
